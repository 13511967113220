export default [
  {
    country: "es",
    language: "Español",
    name: "21 Q's SEAT",
    templateId: "d-8df9eafe225c419084ac60b11d0231ba",
  },
  {
    country: "es",
    language: "Español",
    name: "Mi historia con SEAT",
    templateId: "d-c74b8cc7c2d948758d68b2b85ae42060",
  },
  {
    country: "es",
    language: "Inglés",
    name: "My motivation with SEAT",
    templateId: "d-da193dc1f0d2416cba1d2a00d982b42f",
  },
  {
    country: "es",
    language: "Español",
    name: "Shark Tank",
    templateId: "d-0e433865971142028ff8ad854e38a243",
  },
  {
    country: "es",
    language: "Español",
    name: "Creador de Contenido",
    templateId: "d-0302e48a860a476fab6ebe73ceb236dc",
  },
  {
    country: "es",
    language: "Español",
    name: "SEAT: Video Challenge",
    templateId: "d-a16708fe668f4cf4be3a060159c033b5",
  },
  {
    country: "es",
    language: "Español",
    name: "Rookies League 21 Q’s",
    templateId: "d-42a803ba70e14eb2bafec2b5f19b8b96",
  },
  {
    country: "mx",
    language: "Español",
    name: "21 Q's SEAT",
    templateId: "d-8df9eafe225c419084ac60b11d0231ba",
  },
  {
    country: "mx",
    language: "Español",
    name: "Mi historia con SEAT",
    templateId: "d-c74b8cc7c2d948758d68b2b85ae42060",
  },
  {
    country: "mx",
    language: "Inglés",
    name: "My motivation with SEAT",
    templateId: "d-da193dc1f0d2416cba1d2a00d982b42f",
  },
  {
    country: "mx",
    language: "Español",
    name: "Shark Tank",
    templateId: "d-0e433865971142028ff8ad854e38a243",
  },
  {
    country: "mx",
    language: "Español",
    name: "Creador de Contenido",
    templateId: "d-0302e48a860a476fab6ebe73ceb236dc",
  },
  {
    country: "mx",
    language: "Español",
    name: "SEAT: Video Challenge",
    templateId: "d-a16708fe668f4cf4be3a060159c033b5",
  },
];

<template>
  <div class="vacancies-container is-multiline">
    <div class="is-flex is-justify-content-end mt-4">
      <b-button class="share" size="small" icon-right="file-excel" @click="downloadReport">
        <span>Descargar reporte de posiciones</span>
      </b-button>
    </div>

    <div class="vacancies-grid">
      <SingleStat
        title="Vacantes totales"
        :value="data.total"
        :isLoading="isLoading"
        bgColor="#38bba7"
        textColor="white"
        size="3"
        icon="fa-regular fa-boxes-stacked"
        style="grid-row: 1; grid-column-start: span 11"
      />
      
      <!-- Second line about general statuses -->
      <SingleStat
        title="Creadas"
        :value="data.created"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#38bba7"
        textColor="white"
        size="4"
        icon="fa-regular fa-rectangle-vertical-history"
        style="grid-row: 2; grid-column-start: 1; grid-column-end: 4"
      />
      <SingleStat
        title="Publicadas"
        :value="data.published"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#48c78e"
        textColor="white"
        size="4"
        icon="fa-regular fa-upload"
        style="grid-row: 2; grid-column-start: 4; grid-column-end: 8"
      />
      <SingleStat
        title="Cerradas"
        :value="data.closed?.total"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#c2c2c2"
        textColor="#3c3c3c"
        size="4"
        icon="fa-regular fa-arrow-right-from-bracket"
        style="grid-row: 2; grid-column-start: 8; grid-column-end: 10"
      />
      <SingleStat
        title="Canceladas"
        :value="data.canceled?.total"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#f14668"
        textColor="white"
        size="4"
        icon="fa-regular fa-ban"
        style="grid-row: 2; grid-column-start: 10; grid-column-end: 12"
      />

      <!-- Third line about specific vacancies statuses -->
      <SingleStat
        title="Duplicadas"
        :value="data.duplicated"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#358fca"
        textColor="white"
        size="4"
        icon="fa-regular fa-copy"
        style="grid-row: 3; grid-column-start: 1; grid-column-end: 4"
      />
      <SingleStat
        title="Con 2° terna"
        :value="data.hasSecondRound"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#ffd700"
        textColor="#474b4e"
        size="4"
        icon="fa-regular fa-repeat"
        style="grid-row: 3; grid-column-start: 4; grid-column-end: 8"
      />
      <SingleStat
        title="Cerradas en tiempo"
        :value="data.closed?.onTime"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#dfdfde"
        textColor="#3c3c3c"
        size="4"
        icon="fa-regular fa-hourglass-start"
        style="grid-row: 3; grid-column-start: 8; grid-column-end: 9"
      />
      <SingleStat
        title="Cerradas fuera de tiempo"
        :value="data.closed?.outaTime"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#c2c2c2"
        textColor="#3c3c3c"
        size="4"
        icon="fa-regular fa-hourglass-end"
        style="grid-row: 3; grid-column-start: 9; grid-column-end: 10"
      />

      <SingleStat
        title="Canceladas en tiempo"
        :value="data.canceled?.onTime"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#ff6382"
        textColor="white"
        size="4"
        icon="fa-regular fa-hourglass-start"
        style="grid-row: 3; grid-column-start: 10; grid-column-end: 11"
      />
      <SingleStat
        title="Canceladas fuera de tiempo"
        :value="data.canceled?.outaTime"
        :percentageRange="data.total"
        :isLoading="isLoading"
        bgColor="#f14668"
        textColor="white"
        size="4"
        icon="fa-regular fa-hourglass-end"
        style="grid-row: 3; grid-column-start: 11; grid-column-end: 12"
      />
    </div>

    <SimpleCard
      title="Histórico de Vacantes"
      :isLoading="isLoading"
      icon="fa-regular fa-chart-line"
      description="Cantidad de vacantes creadas por cada mes, en el tiempo seleccionado."
      :data="data"
    >
    <template #content="{ data }">
      <SimpleBarColumn
        :barData="data.timeline"
        :headers="['Fecha', 'Vacantes']"
        chart-height="300px"
        :goals="goals"
      />
    </template>
    </SimpleCard>
  </div>
</template>

<script>
import SingleStat from "./SingleStat.vue";
import SimpleBarColumn from "./SimpleBarColumn.vue";
import SimpleCard from "./SimpleCard.vue";
import moment from "moment";

export default {
  props: {
    loadFunction: {
      type: Function,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
    searchPositionsReport: {
      type: Object,
      required: true,
    },
  },
  components: {
    SingleStat,
    SimpleBarColumn,
    SimpleCard,
  },
  watch: {
    search: {
      handler: async function () {
        await this.getData();
      },
      deep: true,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {
        total: 0,
        created: 0,
        published: 0,
        closed: {
          total: 0,
          onTime: 0,
          outaTime: 0,
        },
        canceled: {
          total: 0,
          onTime: 0,
          outaTime: 0,
        },
        duplicated: 0,
        hasSecondRound: 0,
        timeline: {},
      },
    };
  },
  async mounted() {},
  methods: {
    /* Top level function to get content */
    async getData() {
      this.isLoading = true;
      const data = await this.loadFunction(this.search);
      this.data = data;
      this.isLoading = false;
    },
    async downloadReport () {
      try {
        const response = await this.searchPositionsReport(this.search);
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const documentTitle = `posiciones ${moment(this.search.startDate).format('YYYY-MM-DD')} ${moment(this.search.endDate).format('YYYY-MM-DD')}.xlsx`;

        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = documentTitle;
        document.body.appendChild(a);
        a.click();
        a.remove();
        
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        this.$toast.error({ message: 'Error al descargar el archivo' });
      }
    },
  },
  computed: {
    goals() {
      return {
        'Minimo': {
          value: 12 * this.search.recruiters?.length,
          type: 'line',
          color: '#ffdf00'
        },
        'Está bien':{
          value: 18 * this.search.recruiters?.length,
          type: 'line',
          color: '#18D302'
        }, 
        'Sorprendente': {
          value: 28 * this.search.recruiters?.length,
          type: 'line',
          color: '#208dff'
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vacancies-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.vacancies-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto, 10fr);
  gap: 0.4rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
</style>

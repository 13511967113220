import VacancyDetail from '@/screens/VacancyDetail'
import VacancyEdit from '@/screens/VacancyEdit'
import VacancyCreate from '@/screens/VacancyCreate'
import VacancyCandidates from '@/screens/VacancyCandidates'
import CandidateDetail from '@/screens/CandidateDetail'
import Recruitment from '@/layouts/Recruitment'

import store from '@/store'
import Vue from 'vue'
import { isSpain } from '../utils/getAppLocation'


// Obtiene las empresas del usuario, verifica si tiene permisos para acceder a la empresa y la establece como empresa actual
const checkCompanyPermissions = async (companyId) => {
  const userCompanies = await store.dispatch('getUserCompanies')
  const companyIdOnUserCompanies = userCompanies.includes(companyId)

  if (!companyIdOnUserCompanies) {
    Vue.$toast.warning({
      message: "No tienes permisos para acceder a esta página",
      duration: 3000,
    });

    return false
  }

  return true
}

// Obtienes las vacantes del usuario, verifica si tiene permisos para acceder a la vacante y la establece como vacante actual
const checkVacancyPermissions = async (vacancyId) => {
  const userVacancies = await store.dispatch('getUserVacancies')
  const vacancyIdOnUserVacancies = userVacancies.includes(vacancyId)

  if (!vacancyIdOnUserVacancies) {
    Vue.$toast.warning({
      message: "No tienes permisos para acceder a esta página",
      duration: 3000,
    });

    return false
  }

  return true
}

const recruitment = [
  {
    path: '/vacante',
    component: Recruitment,
    name: 'Vacancy',
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager', 'account-manager', 'account']
    },
    redirect: { name: 'VacancyDetail' },
    children: [
      {
        path: 'nueva',
        component: VacancyCreate,
        name: 'VacancyCreate',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager', isSpain && ['solicitant', 'hrmanager']].flat()
        },
        beforeEnter: async (to, from, next) => {
          try {
            const companyAllowed = await checkCompanyPermissions(to.query?.companyId)
            if (!companyAllowed) {
              next(from.path)
              return
            }
            await store.dispatch('getCurrentCompany', to.query?.companyId)

            next()
            return
          } catch (error) {
            next(from.path)
            return
          }
        }
      },
      {
        path: ':id',
        component: VacancyDetail,
        name: 'VacancyDetail',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        },
        beforeEnter: async (to, from, next) => {

          try {
            const vacancy = await store.dispatch('getCurrentVacancy', to.params.id)

            const companyAllowed = await checkCompanyPermissions(vacancy.company._id) // Compares companyId with userCompanies
            if (!companyAllowed) {
              next(from.path)
              return
            }
            await store.dispatch('getCurrentCompany', vacancy.company._id)

            const vacancyAllowed = await checkVacancyPermissions(to.params.id) // Compares vacancyId with userVacancies
            if (!vacancyAllowed) {
              next(from.path)
              return
            }

            next()
            return
          } catch (error) {
            next(from.path)
            return
          }

        }
      },
      {
        path: ':id/edit',
        component: VacancyEdit,
        name: 'VacancyEdit',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'manager', isSpain && ['solicitant', 'hrmanager']].flat()
        },
        beforeEnter: async (to, from, next) => {
          try {
            const vacancy = await store.dispatch('getCurrentVacancy', to.params.id)

            const companyAllowed = await checkCompanyPermissions(vacancy.company._id) // Compares companyId with userCompanies
            if (!companyAllowed) {
              next(from.path)
              return
            }
            await store.dispatch('getCurrentCompany', vacancy.company._id)

            const vacancyAllowed = await checkVacancyPermissions(to.params.id) // Compares vacancyId with userVacancies
            if (!vacancyAllowed) {
              next(from.path)
              return
            }

            next()
            return

          } catch (error) {
            next(from.path)
            return

          }
        }
      },
      {
        path: ':id/candidatos',
        name: 'VacancyCandidates',
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager', 'account-manager', 'account'],
        },
        component: VacancyCandidates,
        beforeEnter: async (to, from, next) => {
          try {
            const vacancy = await store.dispatch('getCurrentVacancy', to.params.id)
            if (!vacancy) {
              next(from.path)
              return
            }

            const companyAllowed = await checkCompanyPermissions(vacancy.company._id) // Compares companyId with userCompanies
            if (!companyAllowed) {
              next(from.path)
              return
            }
            await store.dispatch('getCurrentCompany', vacancy.company._id)

            const vacancyAllowed = await checkVacancyPermissions(to.params.id) // Compares vacancyId with userVacancies
            if (!vacancyAllowed) {
              next(from.path)
              return
            }

            next()
            return

          } catch (error) {
            next(from.path)
            return
          }

        }
      }
    ]
  },
  {
    path: '/candidato',
    name: 'Candidate',
    component: {
      render: r => r('router-view')
    },
    meta: {
      requireAuth: true,
      permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
    },
    children: [
      {
        path: ':id',
        name: 'CandidateDetail',
        component: CandidateDetail,
        meta: {
          requireAuth: true,
          permissions: ['superqueen', 'recruiter', 'recruiter-manager', 'solicitant', 'hrmanager', 'manager']
        },
        beforeEnter: async (to, from, next) => {
          try {
            const application = await store.dispatch('getCurrentApplication', to.params.id)
            const vacancy = await store.dispatch('getCurrentVacancy', application.vacancy._id)

            const companyAllowed = await checkCompanyPermissions(vacancy.company._id) // Compares companyId with userCompanies
            if (!companyAllowed) {
              next(from.path)
              return
            }
            await store.dispatch('getCurrentCompany', vacancy.company._id)

            const vacancyAllowed = await checkVacancyPermissions(application.vacancy._id) // Compares vacancyId with userVacancies
            if (!vacancyAllowed) {
              next(from.path)
              return
            }
            next()
            return

          } catch (error) {
            next(from.path)
            return
          }

        }
      },
    ]
  }
]

export default recruitment
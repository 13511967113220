<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ academicDataTranslation.title }}
            <f-icon icon="fa-regular fa-graduation-cap" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Degree -->
        <div class="column is-half">
          <ValidationProvider
            :rules="academicDataTranslation.fields.degree.validation"
            :name="academicDataTranslation.fields.degree.name"
            v-slot="{ errors, valid, validate}"
          >
            <b-field
              :label="renameField(academicDataTranslation.fields.degree.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-taginput
                ellipsis
                type="is-primary"
                v-model="form.degree"
                icon="graduation-cap"
                :placeholder="academicDataTranslation.fields.degree.placeholder"
                @blur="validate"
              />
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Academic Level -->
        <div class="column is-half">
          <ValidationProvider
            :rules="academicDataTranslation.fields.academicLevel.validation"
            :name="academicDataTranslation.fields.academicLevel.name"
            v-slot="{ errors, valid, validate }"
          >
            <b-field
              :label="renameField(academicDataTranslation.fields.academicLevel.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <div class="academicOptions">
                <b-checkbox
                  v-for="(level, key) in academicLevel"
                  :key="key"
                  class="academicOptions--option"
                  v-model="form.academicLevel"
                  :native-value="key"
                  @blur="validate"
                >
                  {{  level }}
                </b-checkbox>
              </div>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Minimum semester -->
        <div class="column is-half"
          v-if="form.academicLevel.includes('student')"
        >
          <ValidationProvider
            :rules="`required|max_value:${form.maximumSemester}`"
            :name="academicDataTranslation.fields.minimumSemester.name"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(academicDataTranslation.fields.minimumSemester.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-if="isMexico"
                expanded
                :placeholder="newPlaceholder(academicDataTranslation.fields.minimumSemester.label)"
                icon="user-minus"
                v-model="form.minimumSemester"
              >
                <option
                  v-for="(semester,key) in collegeSemesters"
                  :key="key"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>

              <b-select
                v-else
                expanded
                :placeholder="newPlaceholder(academicDataTranslation.fields.minimumSemester.label)"
                icon="user-minus"
                v-model="form.minimumSemester"
              >
                <option
                  v-for="semester in collegeSemesters"
                  :key="semester.value"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Maximum semester -->
        <div class="column is-half"
          v-if="form.academicLevel.includes('student')"
        >
          <ValidationProvider
            :rules="`required|min_value:${form.minimumSemester}`"
            :name="academicDataTranslation.fields.maximumSemester.name"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(academicDataTranslation.fields.maximumSemester.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                v-if="isMexico"
                expanded
                :placeholder="newPlaceholder(academicDataTranslation.fields.maximumSemester.label)"
                icon="user-plus"
                v-model="form.maximumSemester"
              >
                <option
                  v-for="semester in collegeSemesters"
                  :key="semester.value"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>

              <b-select
                v-if="isSpain"
                expanded
                :placeholder="newPlaceholder(academicDataTranslation.fields.maximumSemester.label)"
                icon="user-plus"
                v-model="form.maximumSemester"
              >
                <option
                  v-for="semester in collegeSemesters"
                  :key="semester.value"
                  :value="semester.value"
                >
                  {{ semester.text }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Excel Level -->
        <div class="column is-half">
          <ValidationProvider
            :rules="academicDataTranslation.fields.excelLevel.validation"
            :name="academicDataTranslation.fields.excelLevel.name"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="renameField(academicDataTranslation.fields.excelLevel.label)"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-select
                expanded
                icon="browsers"
                :placeholder="newPlaceholder(academicDataTranslation.fields.excelLevel.label)"
                v-model="form.excelLevel"
              >
                <option
                  v-for="(level, index) in levels"
                  :key="`excelLevel-${index}`"
                  :value="level"
                >
                  {{ level }}
                </option>
              </b-select>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-half"></div>

        <!-- Graduate date -->
        <div class="column is-half">
          <ValidationProvider
            :rules="academicDataTranslation.fields.graduateDate.validation"
            :name="academicDataTranslation.fields.graduateDate.name"
            v-slot="{ errors, valid }"
          >
            <b-tooltip
              style="width: 100%"
              :label="academicDataTranslation.fields.graduateDate.tooltip"
              position="is-top"
              animated
            >
              <b-field
                :label="renameField(academicDataTranslation.fields.graduateDate.label)"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datepicker
                  append-to-body
                  :placeholder="newPlaceholder(academicDataTranslation.fields.graduateDate.label)"
                  trap-focus
                  type="month"
                  icon="calendar-range"
                  v-model="form.graduateDate"
                ></b-datepicker>
              </b-field>
            </b-tooltip>
          </ValidationProvider>
        </div>

        <!-- Degree date -->
        <div class="column is-half">
          <ValidationProvider
            :rules="academicDataTranslation.fields.degreeDate.validation"
            :name="academicDataTranslation.fields.degreeDate.name"
            v-slot="{ errors, valid }"
          >
            <b-tooltip
              style="width: 100%"
              :label="academicDataTranslation.fields.degreeDate.tooltip"
              position="is-top"
              animated
            >
              <b-field
                :label="renameField(academicDataTranslation.fields.degreeDate.label)"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datepicker
                  trap-focus
                  type="month"
                  icon="calendar-range"
                  :placeholder="newPlaceholder(academicDataTranslation.fields.degreeDate.label)"
                  v-model="form.degreeDate"
                >
                </b-datepicker>
              </b-field>
            </b-tooltip>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import { mapGetters } from "vuex";
  import { academicLevels, excelLevels, semesterLevels } from "../../lib/vacancyDictionaryout";

  export default {
    components: {
    },
    props: {
      academicData: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
      editData: {
        type: Object,
        required: false,
        default : () => {}
      }
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.academicData) {
        this.form = this.academicData;
      }
    },
    data() {
      return {
        form: {
          degree: null,
          academicLevel: [],
          minimumSemester: 0,
          maximumSemester: 0,
          excelLevel: null,
          graduateDate: null,
          degreeDate: null,
        },
      };
    },
    watch: {
      "$i18n.locale"(){
        this.revalidateFields();
      },
      editData: {
        handler: async function () {
          // Solo agregamos los valores definidos en lel formulario de este componente
          // con los valores que vienen de la BD
          const dataFromDB = Object.keys(this.form).reduce((acc, key) => {
            acc[key] = this.editData[key] !== undefined ? this.editData[key] : this.form[key];
            return acc;
          }, {});      
          return this.form = dataFromDB
        },
      },
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      levels () {
        return excelLevels[this.$i18n.locale]
      },
      isMexico() {
        return process.env.VUE_APP_LOCATION === "mx";
      },
      isSpain() {
        return process.env.VUE_APP_LOCATION === "es";
      },
      academicDataTranslation() {
        return this.$t("screens.vacancies.edit.academicData");
      },
      academicLevel () {
        return academicLevels[this.$i18n.locale]
      },
      collegeSemesters () {
        return semesterLevels[this.$i18n.locale]
      }
    },
    methods: {
      async mountedPromises() {
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return this.form
        }
        return false
      },
      translateLabel (text) {
        const translations = {
          "Estudiante": this.academicDataTranslation.fields.academicLevel.options.student,
          "Recién graduado":  this.academicDataTranslation.fields.academicLevel.options.graduated,
          "Graduado con experiencia":  this.academicDataTranslation.fields.academicLevel.options.experienced
        };
        return translations[text] || ""
      },
    revalidateFields() { 
      Object.values(this.$refs.observer.refs).forEach((ref) => {
        if(ref.flags.touched){
          ref.validate();
        }
      })
    }
  },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

  .academicOptions--option {
    margin-top: 2px;
  }
</style>

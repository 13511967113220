<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ languageDataTranslation.title }}
            <f-icon icon="fa-regular fa-language" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
       <!-- Nivel de inglés -->
       <div class="column is-full">
          <div class="language-table">
            <p class="is-size-5 has-text-left has-text-weight-semibold">
              {{ languageDataTranslation.fields.englishLevels.label }}
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
              {{ languageDataTranslation.fields.englishLevels.proficiency.desirable }}
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
              {{ languageDataTranslation.fields.englishLevels.proficiency.basic }}
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
              {{ languageDataTranslation.fields.englishLevels.proficiency.intermediate }}
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">
              {{ languageDataTranslation.fields.englishLevels.proficiency.advanced }} 
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-info-dark">
              {{ languageDataTranslation.fields.englishLevels.proficiency.representative }} 
            </p>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.englishLevels.proficiency.writing }}</p>
            <b-radio
              name="englishWriting"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-radio
              name="englishWriting"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-radio
              name="englishWriting"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-radio
              name="englishWriting"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              v-model="form.englishLevels.writing"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="writing"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.englishLevels.proficiency.reading }}</p>
            <b-radio
              name="englishReading"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-radio
              name="englishReading"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-radio
              name="englishReading"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-radio
              name="englishReading"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              v-model="form.englishLevels.reading"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="reading"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.englishLevels.proficiency.listening }}</p>
            <b-radio
              name="englishListening"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-radio
              name="englishListening"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-radio
              name="englishListening"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-radio
              name="englishListening"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              v-model="form.englishLevels.listening"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="listening"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.englishLevels.proficiency.speaking }}</p>
            <b-radio
              name="englishSpeaking"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-radio
              name="englishSpeaking"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-radio
              name="englishSpeaking"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-radio
              name="englishSpeaking"
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              v-model="form.englishLevels.speaking"
            ></b-radio>
            <b-checkbox
              v-model="form.englishLevels.representativeSkill"
              native-value="speaking"
              required
            ></b-checkbox>
          </div>
        </div>

        <!-- Other languages -->
        <div
          class="column is-full"
          v-for="(language, index) in form.otherLanguages"
          :key="`language-${index}`"
        >
          <div class="column is-4 is-flex pb-0 mt-2">
            <b-input
              class="mr-2"
              style="width: 100%"
              maxlength="24"
              v-model="form.otherLanguages[index].language"
            ></b-input>

            <b-button
              type="is-danger"
              @click="removeLenguaje(index)"
            >
              <f-icon icon="fa-regular fa-trash" />
            </b-button>
          </div>

          <div class="language-table">
            <p class="is-size-5 has-text-weight-semibold">
              {{ form.otherLanguages[index].language }}
            </p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">{{ languageDataTranslation.fields.otherLanguages.proficiency.desirable }}</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">{{ languageDataTranslation.fields.otherLanguages.proficiency.basic }}</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">{{ languageDataTranslation.fields.otherLanguages.proficiency.intermediate }}</p>
            <p class="is-size-6 has-text-weight-medium has-text-grey-dark">{{ languageDataTranslation.fields.otherLanguages.proficiency.advanced }}</p>
            <p class="is-size-6 has-text-weight-medium has-text-info-dark">{{ languageDataTranslation.fields.otherLanguages.proficiency.representative }}</p>
            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.otherLanguages.proficiency.writing }}</p>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.otherLanguages[index].writing"
              :name="`lenguage${index}Writing`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.otherLanguages[index].writing"
              :name="`lenguage${index}Writing`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              :name="`lenguage${index}Writing`"
              v-model="form.otherLanguages[index].writing"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              :name="`lenguage${index}Writing`"
              v-model="form.otherLanguages[index].writing"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="writing"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.otherLanguages.proficiency.reading }}</p>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              v-model="form.otherLanguages[index].reading"
              :name="`lenguage${index}Reading`"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="reading"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.otherLanguages.proficiency.listening }}</p>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              v-model="form.otherLanguages[index].listening"
              :name="`lenguage${index}Listening`"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="listening"
              required
            ></b-checkbox>

            <p class="is-size-6 has-text-left has-text-weight-medium has-text-info">{{ languageDataTranslation.fields.otherLanguages.proficiency.speaking }}</p>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.desirable"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.basic"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.intermediate"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-radio
              :native-value="languageDataTranslation.fields.englishLevels.proficiency.advanced"
              required
              v-model="form.otherLanguages[index].speaking"
              :name="`lenguage${index}Speaking`"
            ></b-radio>
            <b-checkbox
              v-model="form.otherLanguages[index].representativeSkill"
              native-value="speaking"
              required
            ></b-checkbox>
          </div>
        </div>

        <!-- Add language -->
        <div class="column is-3">
          <b-button
            expanded
            type="is-primary"
            @click="addLenguaje"
          >
            <f-icon icon="fa-regular fa-language" class="mr-2"/>
            {{ languageDataTranslation.fields.addLanguageButton.label }}
          </b-button>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { knowledgeLevelsObject } from "../../lib/vacancyDictionaries";

  export default {
    components: {
    },
    props: {
      languages: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
      editData: {
        type: Object,
        required: false,
        default : () => {}
    }
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.languages) {
        this.form = this.languages;
      }
    },
    data() {
      return {
        form: {
          englishLevel: null,
          englishLevels: {
            language: 'Inglés',
            listening: "Intermedio",
            reading: "Intermedio",
            speaking: "Intermedio",
            writing: "Intermedio",
            representativeSkill: ['speaking'],
          },
          otherLanguages: [],
        },
        knowledgeLevelsObject
      };
    },
    watch: {
      "$i18n.locale"(){
        this.revalidateFields();
      },
      editData: {
        handler: async function () {
          // Solo agregamos los valores definidos en lel formulario de este componente
          // con los valores que vienen de la BD
          const dataFromDB = Object.keys(this.form).reduce((acc, key) => {
            acc[key] = this.editData[key] !== undefined ? this.editData[key] : this.form[key];
            return acc;
          }, {});      
          return this.form = dataFromDB;
        },
      },
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      languageDataTranslation() {
        return this.$t("screens.vacancies.edit.languageData");
      },
    },
    methods: {
      async mountedPromises() {
      },
      addLenguaje() {
        this.form.otherLanguages.push({
          language: "",
          writing: "Deseable",
          listening: "Deseable",
          reading: "Deseable",
          speaking: "Deseable",
          representativeSkill: ['speaking'],
        });
      },
      removeLenguaje(index) {
        this.form.otherLanguages.splice(index, 1);
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          this.form.englishLevel = this.form.englishLevels.speaking;
          return this.form
        }
        return false
      },
      revalidateFields() { 
        Object.values(this.$refs.observer.refs).forEach((ref) => {
          if(ref.flags.touched){
            ref.validate();
          }
        })
      }
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";

  .language-table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-items: center;
    align-items: center;
    row-gap: 1rem;
  }
</style>

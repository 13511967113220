<template>
  <div class="container">
    <b-modal
      :active.sync="isOpen"
      :can-cancel="true"
      :on-cancel="close"
      aria-modal
      aria-role="dialog"
      trap-focus
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <header class="modal-card-head">
          <h1 class="title is-5">Enviar reclutamiento alternativo</h1>
        </header>

        <section class="modal-card-body">
          <div class="columns is-multiline">
            <div class="column is-3">
              <ValidationObserver ref="observer">
                <ValidationProvider
                  rules="required"
                  name="Idioma"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    placeholder="Idioma"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-select
                      expanded
                      icon="language"
                      placeholder="Idioma"
                      v-model="languageSelected"
                    >
                      <option
                        v-for="language in availableLanguages"
                        :value="language"
                        :key="language"
                      >
                        {{ language }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </ValidationObserver>
            </div>

            <div class="column is-9">
              <ValidationObserver ref="observer">
                <ValidationProvider
                  rules="required"
                  name="Tipo de reclutamiento alternativo"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    placeholder="Tipo de reclutamiento alternativo"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-select
                      :disabled="!languageSelected"
                      expanded
                      icon="brain-circuit"
                      placeholder="Tipo de reclutamiento alternativo"
                      v-model="templateSelected"
                    >
                      <option
                        v-for="template in filteredTemplates"
                        :value="template"
                        :key="template.name"
                      >
                        {{ template.name }}
                      </option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </ValidationObserver>
            </div>

            <div class="column is-full">
              <b-table
                :data="selectedUsers ? selectedUsers : []"
                :hoverable="true"
                :mobile-cards="true"
                :paginated="true"
                :pagination-simple="true"
                :per-page="10"
                :striped="true"
                pagination-position="top"
              >
                <b-table-column
                  centered
                  field="index"
                  label="#"
                  v-slot="props"
                >
                  {{ props.row.index }}
                </b-table-column>

                <b-table-column
                  centered
                  field="name"
                  label="Nombre"
                  v-slot="props"
                >
                  {{ props.row.profile.firstName }}
                  {{ props.row.profile.firstSurname }}
                  {{ props.row.profile.lastSurname }}
                </b-table-column>

                <b-table-column
                  centered
                  field="studyArea"
                  label="Area de estudio"
                  v-slot="props"
                >
                  {{ props.row.profile.studyArea }}
                </b-table-column>

                <b-table-column
                  centered
                  field="typeCustom"
                  label="Tipo de reclutamiento"
                  v-slot="props"
                >
                  <span
                    v-if="props.row.typeCustomRecruitment"
                    class="tag is-dark"
                  >
                    {{ props.row.typeCustomRecruitment }}
                  </span>
                  <span
                    v-else
                    class="tag is-light"
                  >
                    {{ "Normal" }}
                  </span>
                </b-table-column>

                <b-table-column
                  centered
                  field="delete"
                  label="Eliminar"
                  v-slot="props"
                >
                  <b-button
                    @click="deleteCandidate(props.row.index)"
                    icon-right="xmark"
                    size="is-small"
                    type="is-warning"
                  />
                </b-table-column>
              </b-table>
            </div>
          </div>
        </section>

        <footer class="modal-card-foot justify-flex-end">
          <b-button
            icon-left="xmark"
            type="is-danger"
            @click="close"
          >
            Cancelar
          </b-button>
          <b-button
            :disabled="selectedUsers.length === 0"
            icon-left="envelopes-bulk"
            type="is-primary"
            @click="validateCustomRecruitment"
          >
            Enviar
          </b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import options from "@/lib/customRecruitment.js";

  export default {
    props: {
      selectedUsers: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    data() {
      return {
        isOpen: false,
        languages: [],
        languageSelected: null,
        templateSelected: null,
        templates: [],
        location: process.env.VUE_APP_LOCATION || "mx",
      };
    },
    computed: {
      filteredOptions() {
        // Filtrar las opciones según la locación
        return options.filter((option) => option.country === this.location);
      },
      availableLanguages() {
        // Extraer los idiomas únicos de las opciones filtradas
        return [...new Set(this.filteredOptions.map((option) => option.language))];
      },
      filteredTemplates() {
        // Filtrar las plantillas por el idioma seleccionado
        return this.filteredOptions.filter((option) => option.language === this.languageSelected);
      },
    },
    methods: {
      open() {
        this.isOpen = true;
      },
      close() {
        this.cleanComponent();
        this.isOpen = false;
      },
      cleanComponent() {
        (this.languages = []), (this.languageSelected = null), (this.templateSelected = null), (this.templates = []);
      },
      deleteCandidate(index) {
        this.$emit("delete-candidate", index);
      },
      async prepareRequest() {
        const valid = await this.$refs.observer.validate();
        if (valid) {
          const applicationsId = [...new Set(this.selectedUsers.map((candidate) => candidate.applicationId))];
          const template = this.templateSelected;

          this.requestCustomRecruitment({
            applicationsId,
            template,
          });
        }
      },
      async validateCustomRecruitment() {
        const valid = await this.$refs.observer.validate();
        if (valid) {
          const usersWithCustomRecruitment = this.selectedUsers.filter(
            (user) => user.typeCustomRecruitment !== undefined
          );

          if (usersWithCustomRecruitment.length > 0) {
            this.$buefy.dialog.confirm({
              title: "Usuarios invitados",
              message:
                "En tu lista de selección, hay usuarios a quienes ya se les ha enviado un reclutamiento alternativo. <b>¿Te gustaría sobreescribirlo?.</b>",
              cancelText: "Conservar",
              confirmText: "Remover",
              type: "is-warning",
              hasIcon: true,
              onCancel: () => this.keepUsersWithCustomRecruitment(),
              onConfirm: () => this.removeUsersWithCustomRecruitment(usersWithCustomRecruitment),
            });
          } else {
            this.prepareRequest();
          }
        }
      },
      keepUsersWithCustomRecruitment() {
        this.$toast.info({
          duration: 5000,
          message: "Conservados",
        });
        this.prepareRequest();
      },
      removeUsersWithCustomRecruitment(users) {
        users.forEach((user) => {
          this.deleteCandidate(user.index);
        });
        this.$toast.warning({
          duration: 5000,
          message: "Removidos",
        });
      },
      async requestCustomRecruitment(payload) {
        this.isLoading = true;
        try {
          await this.$api.post("application/vacancy/type-custom-recruitment", payload);
          this.$emit("update");
          this.close();
        } catch (error) {
          this.$toast.error({
            message: error,
          });
          this.cleanComponent();
        }
        this.isLoading = false;
      },
    },
    watch: {
      languageSelected() {
        this.templates = this.filteredTemplates;
      },
    },
    mounted() {
      this.languages = this.availableLanguages;
    },
  };
</script>

<style lang="scss" scoped></style>

<template>
  <div class="vacancy-card">

    <!-- Nombre y status de la vacante -->
    <div class="title-box">
      <router-link :to="{
        name: 'VacancyDetail',
        params: { id: vacancy._id }, 
        query: { from: routeRedirectionName } }"
        class="has-text-weight-semibold is-size-4 title-text has-text-primary" 
        target="_blank"
      >
        {{ vacancy.name }}
      </router-link>

      <b-tag 
        :type="`is-${vacancyStatuses[vacancy.status].color}`"
        class="is-flex is-jusfiy-content-flex-end"
        size="is-medium"
        rounded
      >
        {{ vacancyStatuses[vacancy.status].text }}
      </b-tag>
    </div>

    <!-- TAGS DE VACANTES -->
    <div class="mb-1 mt-2 vacancy-tags">
      <div class="vacancy-tags-section">
        <!-- Fecha de última actualización -->
        <b-tooltip
          position="is-bottom"
          label="Última actualización"
        >
          <b-tag type="is-primary is-light" rounded>
            <f-icon icon="fa-regular fa-clock-rotate-left" class="mr-1"/>
            {{ vacancy.updatedAt | ago }}
          </b-tag>
        </b-tooltip>

        <!-- Fecha de publicación -->
        <b-tooltip
          position="is-bottom"
          label="Última publicación"
          v-if="vacancy.publishedAt"
        >
          <b-tag type="is-primary is-light" rounded>
            <f-icon icon="fa-regular fa-upload" class="mr-1"/>
            {{ vacancy.publishedAt | ago }}
          </b-tag>
        </b-tooltip>
        
      </div>
      <div class="vacancy-tags-section is-justify-content-flex-end">
        <!-- Proyecto -->
        <b-tooltip
          position="is-left"
          label="Proyecto"
          type="is-info is-light"
          v-if="vacancy.projectId?.name"
        >
          <b-tag type="is-info is-light" rounded>
              <f-icon icon="fa-regular fa-list-check" class="mr-1"/>
              {{ vacancy.projectId.name }}
          </b-tag>
        </b-tooltip>
      </div>
    </div>

    <!-- INFORMACIÓN VACANTE -->
    <div class="main-content">
      <!-- info general -->
      <div class="column px-3 py-2">
        <!-- Reclutador -->
        <p class="has-text-weight-semibold is-size-6 mt-1">
          <b-tooltip
            position="is-bottom"
            label="Reclutador asignado"
          >
            <f-icon icon="fa-regular fa-user-magnifying-glass" class="has-text-primary"/>
            <span>
              {{ getRecruiterName(vacancy.recruiter) }}
            </span>
          </b-tooltip>
        </p>

        <!-- Reclutadores adicionales -->
        <p class="has-text-weight-semibold is-size-6 mt-1" v-if="vacancy.additionalRecruiters.length">
          <b-tooltip
            position="is-bottom"
            label="Reclutadores adicionales"
          >
            <div class="is-flex">
              <f-icon icon="fa-regular fa-users" class="has-text-primary mr-2 mt-1"/>
              <div class="is-flex is-flex-direction-column">
                <p v-for="(recruiter, index) in vacancy.additionalRecruiters" :key="`aditional-${index}`">
                  {{ getRecruiterName(recruiter) }}
                </p>
              </div>
            </div>
          </b-tooltip>
        </p>

        <!-- Id de monday -->
        <p class="is-size-6 is-flex justify-content-between mt-1" v-if="isMexico">
          <b-tooltip
            position="is-top"
            :label="`Id de ${ vacancy.projectMondayId ? 'requisición' : 'vacante' } en Monday`"
          >
            <img
              src="@/assets/logo-monday.svg"
              alt="ProMeritum"
              width="20px"
            >
            <span class="ml-1 is-size-6">
              {{ vacancy.projectMondayId || vacancy.mondayVacancyId || 'Sin asignar'  }}
            </span>
          </b-tooltip>
        </p>

        <!-- Solicitante -->
        <p class="is-size-6 mt-1">
          <b-tooltip
            position="is-top"
            label="Solicitante designado"
          >
            <f-icon icon="fa-regular fa-person-circle-question" class="has-text-primary mr-1"/>
            <span>
                {{ getSolicitantName(vacancy.author) }}
            </span>
          </b-tooltip>
        </p>

        <!-- Solicitantes adicionales -->
        <p class=" is-size-6 mt-1" v-if="vacancy.additionalAuthors?.length">
          <b-tooltip
            position="is-top"
            label="Solicitantes adicionales"
          >
            <div class="is-flex">
              <f-icon icon="fa-regular fa-users-line" class="has-text-primary mr-2 mt-1"/>
              <div class="is-flex is-flex-direction-column">
                <p v-for="(author, index) in vacancy.additionalAuthors" :key="`author-${index}`">
                  {{ getSolicitantName(author) }}
                </p>
              </div>
            </div>
          </b-tooltip>
        </p>

        <!-- Motivos de cierre -->
        <div v-if="vacancy.status === 'closed' && vacancy.closedReason?.reasons">
          <hr class="separation-line">
          <p class="mb-1 has-text-weight-medium has-text-primary">
            Resumen de cierre
          </p>

          <!-- Motivos -->
          <div v-if="vacancy.closedReason?.reasons">
            <b-collapse :open="false" aria-id="reasonsRejection" class="mb-2" animation="slide">
              <template #trigger="props">
                  <b-button
                    :label="`${props.open ? 'Ocultar' : 'Ver'} motivos`"
                    type="is-grey is-outlined"
                    aria-controls="reasonsRejection"
                    :aria-expanded="props.open"
                    size="is-small"
                    icon-left="fa-comments-question"
                    rounded
                  />
              </template>

              <div class="box rounded-5 px-2 py-1 is-flex is-flex-direction-column is-size-7"
              >
                <span
                  v-for="(reason, index) in vacancy.closedReason.reasons"
                  :key="`reason-${index}`"
                  :class="`${index !== 0 ? 'collapse-item' : ''}`"
                >
                  {{ reason }}
                </span>
              </div>
            </b-collapse>

            <!-- Recuento de posiciones -->
            <b-collapse :open="false" aria-id="countRejection" animation="slide">
              <template #trigger="props">
                  <b-button
                    :label="`${props.open ? 'Ocultar' : 'Ver'} recuento de posiciones`"
                    type="is-grey is-outlined"
                    aria-controls="countRejection"
                    :aria-expanded="props.open"
                    size="is-small"
                    icon-left="fa-folder-tree"
                    rounded
                  />
              </template>

              <div class="box px-2 py-1 is-flex is-flex-direction-column is-size-7"
              >
                <span v-if="vacancy.closedReason?.filledPositions">
                  {{ vacancy.closedReason.filledPositions }} 
                  cubierta(s) por Pro Meritum
                </span>

                <span v-if="vacancy.closedReason?.closedPositions" class="collapse-item">
                  {{ vacancy.closedReason.closedPositions }}
                  cerrada(s) por empresa {{ companyInfo.name }}
                </span>

                <span v-if="vacancy.closedReason?.canceledPositions" class="collapse-item">
                  {{ vacancy.closedReason.canceledPositions }}
                  cancelada(s) por empresa {{ companyInfo.name }}
                </span>
              </div>
            </b-collapse>
          </div>
        </div>

        <!-- Duplicación -->
        <div v-if="vacancy.duplicateReason">
          <hr class="separation-line" >
          <p class="mb-1 has-text-weight-medium has-text-primary">
            Duplicación
          </p>

          <router-link :to="{
            name: 'VacancyDetail',
            params: { id: vacancy.relatedTo } }"
            class="has-text-weight-semibold is-size-6 has-text-grey"
          >
            <f-icon icon="fa-regular fa-up-right-from-square" class="has-text-grey-light mr-1"/>
            Ver vacante original
          </router-link>

          <p class="is-size-6">
            <b-tooltip
              position="is-top"
              label="Razón de duplicación"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-lightbulb-exclamation" class="has-text-grey-light mr-1"/>
              {{ duplicateReasons[vacancy.duplicateReason] }}
            </span>
            </b-tooltip>
          </p>
        </div>
        
        <!-- Fechas -->
        <div class="is-size-6 mt-1">
          <hr class="separation-line">
          <p class="mb-1 has-text-weight-medium has-text-primary">
            Agenda
          </p>

          <p>
            <b-tooltip
              position="is-top"
              label="Fecha de alta"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-calendar-arrow-up" class="has-text-grey-light mr-1"/>
              {{ vacancy.createdAt | formatToDate }}
            </span>
            </b-tooltip>
          </p>

          <p class="mt-2" v-if="vacancy.phoneCallDate.length > 0  && isMexico">
            <b-tooltip
              position="is-top"
              label="Fecha de llamada de alineación"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-calendar-users" class="has-text-grey-light mr-1"/>
              {{ vacancy.phoneCallDate[0].startDate | formatToDate }}
            </span>
            </b-tooltip>
          </p>

          <p class="mt-2"  v-if="vacancy.commitmentDate && isMexico">
            <b-tooltip
              position="is-top"
              label="Fecha de compromiso"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-calendar-clock" class="has-text-grey-light mr-1"/>
              {{ vacancy.commitmentDate.startDate | formatToDate }}
            </span>
            </b-tooltip>
          </p>

          <p class="mt-2" v-if="vacancy.realDeliveryDate || vacancy.deliveryDate">
            <b-tooltip
              position="is-top"
              label="Fecha de entrega real"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-calendar-check" class="has-text-grey-light mr-1"/>
              {{ (vacancy.realDeliveryDate || vacancy.deliveryDate.startDate) | formatToDate }}
            </span>
            </b-tooltip>
          </p>

          <p class="mt-2" v-if="vacancy.closedAt">
            <b-tooltip
              position="is-top"
              label="Fecha de cierre"
            >
            <span type="is-primary">
              <f-icon icon="fa-regular fa-calendar-circle-minus" class="has-text-grey-light mr-1"/>
              {{ vacancy.closedAt | formatToDate }}
            </span>
            </b-tooltip>
          </p>
        </div>
        
      </div>

      <!-- Info particular -->
      <div class="column px-3 py-2"
        style="border-left: 1px solid #dcdcdc40;"
      >
        <p class="mb-1 has-text-weight-medium has-text-primary">
          Detalles de vacante
        </p>

        <!-- Ubicación -->
        <p class="is-size-6 mt-1">
          <b-tooltip
            position="is-top"
            label="Ubicación de la vacante"
          >
          <span type="is-primary">
            <f-icon icon="fa-regular fa-location-dot" class="has-text-grey-light mr-1"/>
            {{ vacancy.location.state }},  {{ vacancy.location.city }}
          </span>
          </b-tooltip>
        </p>

        <!-- Duración -->
        <p class="is-size-6 mt-1">
          <b-tooltip
            position="is-top"
            label="Duración de la vacante"
          >
          <span type="is-primary">
            <f-icon icon="fa-regular fa-clock" class="has-text-grey-light mr-1"/>
            {{ getDurationText(vacancy) }}
          </span>
          </b-tooltip>
        </p>

        <!-- Requerimientos de estudios -->
        <hr class="separation-line">
        <p class="mb-1 has-text-weight-medium has-text-primary">
          Requerimientos de estudio
        </p>

        <!-- Término de materias -->
        <p class="is-size-6">
          <b-tooltip
            position="is-top"
            label="Fecha de término de materias"
          >
          <span type="is-primary">
            <f-icon icon="fa-regular fa-hourglass-half" class="has-text-grey-light mr-1"/>
            {{ vacancy.graduateDate | formatToDateMonth }}
          </span>
          </b-tooltip>
        </p>

        <!-- Nigel de inglés -->
        <p class="is-size-6 mt-1">
          <b-tooltip
            position="is-top"
            label="Nivel de inglés"
          >
          <span type="is-primary">
            <f-icon icon="fa-regular fa-language" class="has-text-grey-light mr-1"/>
            {{ vacancy.englishLevel }}
          </span>
          </b-tooltip>
        </p>

        <!-- Nivel de excel -->
        <p class="is-size-6 mt-1">
          <b-tooltip
            position="is-top"
            label="Nivel de Excel"
          >
          <span type="is-primary">
            <f-icon icon="fa-regular fa-file-excel" class="has-text-grey-light mr-1"/>
            {{ vacancy.excelLevel }}
          </span>
          </b-tooltip>
        </p>

        <!-- Otros idiomas -->
        <p class="is-size-6 mt-1"
          v-for="(language, index) in vacancy.otherLanguages"
          :key="`language-${index}`"
        >
          <b-tooltip
            position="is-top"
            :label="`Nivel de ${language.language}`"
          >
          <span type="is-primary">
            <f-icon icon="fa-regular fa-earth-europe" class="has-text-grey-light mr-1"/>
            {{ language.speaking }}
          </span>
          </b-tooltip>
        </p>

        <!-- Carreras -->
        <b-collapse :open="false" aria-id="reasonsRejection" class="mt-2" animation="slide">
          <template #trigger="props">
              <b-button
                :label="`${props.open ? 'Ocultar' : 'Ver'} carreras`"
                type="is-grey is-outlined"
                aria-controls="reasonsRejection"
                :aria-expanded="props.open"
                size="is-small"
                icon-left="fa-graduation-cap"
                rounded
              />
          </template>

          <div class="box px-2 py-1 is-flex is-flex-direction-column is-size-7"
          >
            <span
              v-for="(career, index) in vacancy.degree"
              :key="`career-${index}`"
              :class="`${index !== 0 ? 'collapse-item' : ''}`"
            >
              {{ career }}
            </span>
          </div>
        </b-collapse>
      </div>

      <!-- Acciones -->
      <div class="column is-1 is-flex is-flex-direction-column align-flex-end justify-space-between">

        <!-- Editar -->
        <b-tooltip label="Editar vacante" type="is-info is-light" position="is-left" v-if="isSpain" >
          <b-button class="mt-1 mb-1" type="is-info" outlined rounded icon-right="pen-to-square"
            @click="goTo({ name: 'VacancyEdit', params: { id: vacancy._id }, query: {from: routeRedirectionName} })"
            ></b-button>
        </b-tooltip>

        <div class="is-flex is-flex-direction-column align-flex-end"
        v-if="(($hasPermissionsByRole(vacancyManagementAllowedRoles) || 
              $hasPermissionsByCompany(companyInfo, false) || 
              $hasPermissionsByVacancy(vacancy)) && !$hasPermissionsByRole(['recruiter']) ) 
              || $hasPermissionsByRole(['recruiter']) && ($hasPermissionsByVacancy(vacancy) || $hasPermissionsByCompany(companyInfo, false))
              "
        >
          <!-- Publicar -->
          <b-tooltip label="Publicar vacante" type="is-primary is-light" position="is-left">
            <b-button class="mt-1 mb-1" type="is-primary" outlined icon-right="upload" rounded
              v-if="vacancy.status === 'created' "
              :disabled="vacancy.phoneCallDate.length < 1 && isMexico"
              @click="publish(vacancy._id)"
            ></b-button>
          </b-tooltip>

          <!-- Editar -->
          <b-tooltip label="Editar vacante" type="is-info is-light" position="is-left">
            <b-button class="mt-1 mb-1" type="is-info" outlined rounded icon-right="pen-to-square"
              @click="goTo({ name: 'VacancyEdit', params: { id: vacancy._id }, query: {from: routeRedirectionName} })"
              ></b-button>
          </b-tooltip>

          <!-- Asignar especialista -->
          <b-tooltip label="Asignar especialista" type="is-info is-light" position="is-left">
            <b-button class="mt-1 mb-1" type="is-info" outlined rounded icon-right="fa-user-magnifying-glass"
              @click="openDialogAssignRecruiter(vacancy)"
            ></b-button>
          </b-tooltip>

          <!-- Pausar/Reanudar vacante -->
          <b-tooltip :label="`${vacancy.isActive ? 'Suspender' : 'Reanudar'} vacante`" type="is-warning is-light" position="is-left">
            <b-button class="mt-1 mb-1" type="is-warning is-light" outlined 
              :icon-right="`fa-${vacancy.isActive ? 'circle-pause': 'circle-play'}`" rounded
              @click="toggleIsActive(vacancy._id, vacancy.name, vacancy.isActive)"
            ></b-button>
          </b-tooltip>

          <!-- Deplegable -->
          <b-tooltip label="Más opciones" type="is-light" position="is-left">

            <b-dropdown class="mt-1 mb-1" :triggers="['click']" aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-button class="p-3" rounded>
                  <f-icon size="xl" icon="fa-regular fa-ellipsis"></f-icon>
                </b-button>
              </template>

              <!-- Completar alineación -->
              <b-dropdown-item aria-role="listitem"
                v-if="isMexico"
                @click="goTo({ name: 'VacancyEdit', params: { id: vacancy._id } , query:{ alligment:  true, from: routeRedirectionName}})"
                :disabled="vacancy.phoneCallDate.length > 0"
                >
                <f-icon icon="fa-regular fa-circle-phone" class="has-text-success"/>
                Completar alineación
              </b-dropdown-item>

              <!-- Ver vacante publicada -->
              <b-dropdown-item aria-role="listitem"
                v-if="vacancy.status === 'published'"
                >
                <a :href="`${publicUrl}/vacancies/${vacancy._id}`" target="_blank" 
                  >
                  <f-icon icon="fa-regular fa-globe-pointer" class="has-text-link"/>
                  <span class="has-text-dark">
                    Ver vacante publicada
                  </span>
                </a>
              </b-dropdown-item>

              <!-- Asignar solicitante -->
              <b-dropdown-item aria-role="listitem"
                @click="openDialogAssignSolicitant(vacancy)"
                >
                <f-icon icon="fa-regular fa-person-circle-question" class="has-text-info"/>
                Designar solicitante
              </b-dropdown-item>

              <!-- Ver proyecto (only visible on vacancies screen) -->
              <b-dropdown-item aria-role="listitem"
                v-if="vacancy.projectId?._id && atVacanciesScreen" 
                @click="goTo({ name: 'Projects', query: { project: vacancy.projectId._id } })"
                >
                <f-icon icon="fa-regular fa-list-check" class="has-text-info"/>
                Ver proyecto
              </b-dropdown-item>

              <!-- Duplicar -->
              <b-dropdown-item aria-role="listitem"
                @click="openDialogDuplicateVacancy(vacancy._id, vacancy.name)"
                >
                <f-icon icon="fa-regular fa-copy" class="has-text-success"/>
                Duplicar
              </b-dropdown-item>

              <!-- Reabrir -->
              <b-dropdown-item aria-role="listitem"
                @click="reOpen(vacancy._id)"
                v-if="['canceled', 'closed'].includes(vacancy.status)"
                :disabled="!$hasPermissionsByRole(vacancyManagementAllowedRoles)"
              >
                <f-icon icon="fa-regular fa-up-from-bracket" class="has-text-warning-dark"/>
                Reabrir
              </b-dropdown-item>

              <!-- Cerrar -->
              <b-dropdown-item aria-role="listitem"
                @click="openDialogCloseVacancy(vacancy)"
                v-if="!['canceled', 'closed'].includes(vacancy.status)"
                >
                <f-icon icon="fa-regular fa-arrow-right-from-bracket" class="has-text-danger"/>
                Cerrar
              </b-dropdown-item>

              <!-- Cancelar -->
              <b-dropdown-item aria-role="listitem"
                @click="cancel(vacancy._id)"
                v-if="!['canceled', 'closed'].includes(vacancy.status)"
                >
                <f-icon icon="fa-regular fa-ban" class="has-text-dark"/>
                Cancelar
              </b-dropdown-item>
            </b-dropdown>
          </b-tooltip>
        </div>

        <!-- Ver vacante publicada (para externos) -->
        <b-tooltip label="Ver vacante publicada" type="is-info is-light" position="is-left">
          <b-button type="is-info is-light" outlined icon-right="globe-pointer" rounded
            v-if="vacancy.status === 'published' && $hasPermissionsByRole(externalRoles)"
            @click="goTo(`${publicUrl}/vacancies/${vacancy._id}`)"
          ></b-button>
        </b-tooltip>

        <!-- Ver candidatos -->
        <b-tooltip label="Ver candidatos" type="is-success is-light" position="is-left">
          <b-button type="is-success is-light" outlined icon-right="people-group" rounded
          @click="goTo({ name: 'VacancyCandidates', params: { id: vacancy.id } , query: { from: routeRedirectionName } })"
          v-if="$hasPermissionsByRole(vacancyManagementAllowedRoles) || 
              $hasPermissionsByCompany(companyInfo, true) || 
              $hasPermissionsByVacancy(vacancy)"
          ></b-button>
        </b-tooltip>
      </div>
    </div>
    
    <!-- POSICIONES -->
    <div style="width: 100%;" class="mt-1">
      <b-progress 
            type="is-primary"
            :value="vacancy.covered" 
            size="is-medium" 
            show-value
            :max="vacancy.positions"
        >
        <f-icon icon="fa-regular fa-user-check" class="mr-1"/>
          {{ vacancy.covered }} / {{ vacancy.positions }} posiciónes cubiertas
        </b-progress>
    </div>
        
  <DuplicateVacancy
    ref="duplicateVacancyModal"
    @update="$emit('update')"  
  />

  <VacancyAssignRecruiter
    ref="assignRecruiterModal"
    @update="$emit('update')" 
  />

  <VacancyAssignSolicitant
    ref="assignSolicitantModal"
    @update="$emit('update')"
  />

  <CloseVacancy
    ref="closeVacancyModal"
    @update="$emit('update')"
  />

  <b-loading :active.sync="isLoading" />
  </div>
</template>

<script>
import duplicateReasons from '@/lib/duplicateReasons'
import durationOptions from '@/lib/durationOptions'
import { externalRoles, vacancyManagementAllowedRoles } from '@/lib/permissions'
import vacancyStatuses from '@/lib/vacancyStatuses'
import { mapGetters } from 'vuex'
import CloseVacancy from '../modals/CloseVacancy.vue'
import DuplicateVacancy from '../modals/DuplicateVacancy.vue'
import VacancyAssignRecruiter from '../modals/VacancyAssignRecruiter.vue'
import VacancyAssignSolicitant from '../modals/VacancyAssignSolicitant.vue'
import { isSpain } from '../../utils/getAppLocation'

export default {
  name: 'ProjectVacancy',
  props:{
    vacancy:{
      type: Object,
      default: () => ({}),
      required: true
    },
    companyInfo:{
      type: Object,
      default: () => ({}),
      required: false
    },
    recruiters:{
      type: Array,
      required: false
    },
    companyUsers:{
      type: Array,
      required: false
    }
  },
  components: {
    DuplicateVacancy,
    VacancyAssignRecruiter,
    VacancyAssignSolicitant,
    CloseVacancy
  },
  data(){
    return{
      durationOptions,
      externalRoles,
      vacancyManagementAllowedRoles,
      vacancies: [],
      vacancyStatuses,
      isLoading: false,      
      duplicateReasons,
      isSpain
    }
  },
  async mounted() {
  },
  computed: {
    ...mapGetters(['companyId']),
    publicUrl () {
      return `${process.env.VUE_APP_PUBLIC_URL}`
    },
    isMexico(){
      return process.env.VUE_APP_LOCATION === 'mx'
    },
    routeRedirectionName(){
      if (this.$route.name !== 'Vacancies') // Set only if not in vacancies page
        return this.$route.name.toLowerCase()
      else 
        return undefined
    },
    atVacanciesScreen(){
      return this.$route.name === 'Vacancies'
    }
  },
  watch: {
  },
  methods: {
    getRecruiterName(id){ 
      const recruiter = this.recruiters.find( recruiter => recruiter.id === id)
      return recruiter?.fullName ? recruiter.fullName : 'Sin Asignar'
    },
    getSolicitantName(id){ 
      const solicitant = this.companyUsers.find( user => user.id === id)
      return solicitant?.fullName ? solicitant.fullName : 'Sin Asignar'
    },
    getDurationText(vacancy){
      return this.durationOptions.find( option => option.value == vacancy.newDuration)?.text || vacancy.duration
    },
    async publish (vacancy) { 
      try {
        await this.$api.post(`/vacancies/${vacancy}/publish`)
        this.$emit('update')
        this.$toast.success({
          message: 'La vacante se publicó',
        });
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
    },
    async toggleIsActive (id, name, isActive) { 
      const  activateMessage = 'Si decides reanudar la vacante, esta se mostrará en el portal de vacantes y recibirá nuevas aplicaciones.'
      const holdMessage = 'Si decides suspender la vacante, esta no se mostrará en el portal de vacantes y no recibirá nuevas aplicaciones.'

      this.$buefy.dialog.confirm({
        title: `¿${ isActive ? 'Suspender' : 'Reanudar' } ${name}?`,
        message: isActive ? holdMessage : activateMessage,
        confirmText: `Si, ${ isActive ? 'suspender' : 'reanudar' }`,
        cancelText: 'No, cancelar',
        type: 'is-info',
        hasIcon: true,
        onConfirm: async () => await this.toggleVacancy(id)
      })
    },
    async toggleVacancy(id){
      try{
        await this.$api.post(`/vacancies/${id}/activate`)
        this.$toast.success({
          message: 'Cambio aplicado',
        });
        this.$emit('update')
      }catch(error){
        this.$toast.error({
          message: "Configuración no aplicada",
        });
      }
    },
    async reOpen (id) { 
      this.$buefy.dialog.confirm({
        title: `¿Regresar candidatos a En aplicación?`,
        message: `Estás reabriendo la vacante, esto la regresará a un estado "Publicada".
                  ¿Deseas que también los candidatos que fueron rechazados regresen a En aplicación?`,
        confirmText: 'Si, regresarlos',
        cancelText: 'No, solo reabrir',
        type: 'is-warning',
        hasIcon: true,
        canCancel: true,
        onConfirm: async () => await this.reOpenVacancy(id, true),
        onCancel: async () => await this.reOpenVacancy(id, false)
      })
    },
    async reOpenVacancy(id, returnRejectedApplications){
      this.isLoading = true

      const payload = {
        returnRejectedApplications
      }
      try {
        await this.$api.post(`/vacancies/${id}/reopen-vacancy`, payload)
        this.$emit('update')
        this.$toast.success({
          message: 'La vacante se reabrió',
        });
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
      this.isLoading = false
    },
    async cancel (id) { 
      this.$buefy.dialog.confirm({
        title: `¿Cancelar la vacante?`,
        message: `Si decides cancelar la vacante, no podrás publicarla.
                  En cambio, puedes crear una vacante duplicada con las mismas especificaciones si lo necesitas.`,
        confirmText: 'Si, cancelar vacante',
        cancelText: 'No, cancelar',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => await this.cancelVacancy(id)
      })
    },
    async cancelVacancy (id) {
      this.isLoading = true
      try {
        await this.$api.post(`/vacancies/${id}/cancel`)
        this.$emit('update')
        this.$toast.success({
          message: 'La vacante se canceló',
        });
      } catch (error) {
        this.$toast.error({
          message: error,
        });
      }
      this.isLoading = false
    },
    openDialogAssignRecruiter(vacancy){ 
      const recommendationParams = [
        `sectorSelected=${this.companyInfo.sector}`,
        `scholarshipSelected=${ this.vacancy.scholarship ? this.vacancy.scholarship : 0 }`,
      ].join('&')

      this.$refs.assignRecruiterModal.open(vacancy, recommendationParams);
    },
    openDialogAssignSolicitant(id, name, authors){ 
      this.$refs.assignSolicitantModal.open(id, name, authors)
    },
    openDialogDuplicateVacancy(id, name){ 
      this.$refs.duplicateVacancyModal.open(id, name)
    },
    openDialogCloseVacancy(id){ 
      this.$refs.closeVacancyModal.open(id)
    },
    goTo( params ){ 
      if (typeof params === 'string') {
        window.open(params, '_blank')
      } else {
        this.$router.push(params)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .vacancy-card{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 0;
    border-radius: 0.8rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #dcdcdc;
  }
  .vacancy-card:hover{
    box-shadow: 0 0 5px 0 #dcdcdc;
  }
  .title-box{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem;
    padding-top: 0.6rem;
  }
  .title-text{
    max-width: 80%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  .vacancy-tags{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .vacancy-tags-section{
    padding: 0 0.9rem;
    gap: 0.5rem;
    display: flex;
  }
  .main-content{
    padding: 0 0.3rem;
    margin-bottom: 0.4rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  .separation-line{
    margin: 0.5rem 0;
    height: 2px;
  }
  .collapse-item{
    border-top: 1px solid #dcdcdc; 
    margin-top: 5px; 
    padding-top: 5px;
  }
</style>

<template>
  <div v-show="showRequirements" 
  :class="['password-requirments-container',customClass]" >
    <div class="password-requirements">
      <p :class="{ 'is-success': hasUpperCase }">✔ Al menos una letra mayúscula</p>
      <p :class="{ 'is-success': hasLowerCase }">✔ Al menos una letra minúscula</p>
      <p :class="{ 'is-success': hasNumber }">✔ Al menos un número</p>
      <p :class="{ 'is-success': hasSpecialChar }">✔ Al menos un carácter especial</p>
      <p :class="{ 'is-success': hasMinLength }">✔ Mínimo 12 caracteres</p>
    </div>
    <div class="password-requirments-triangle"></div>
  </div>
</template>

<script>
export default {
    props: {
    customClass: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      required: true,
    },
    showRequirements: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasUpperCase() {
      return /[A-ZÑ]/.test(this.password);
    },
    hasLowerCase() {
      return /[a-zñ]/.test(this.password);
    },
    hasNumber() {
      return /\d/.test(this.password);
    },
    hasSpecialChar() {
      return /[!@#$%^&*()\-_=+{}[\]|\\:;"'<>,.?/~]/.test(this.password);
    },
    hasMinLength() {
      return this.password.length >= 12;
    }
  }
};
</script>

<style>
.password-requirments-container {
  position: absolute;
  background: #fdfdfd;
  padding: 1rem 2rem;
  border-radius: 5px;
  top: -20%;
  height: fit-content;
  filter: drop-shadow(0 0 2px rgba(0,0,0,0.3));
  min-width: 300px;
  right: 10px;
}

.password-requirments-triangle {
  --triangle-size: 15px;
  position: absolute;
  width: 0;
  border-left: var(--triangle-size) solid transparent;
  border-right: var(--triangle-size) solid transparent;
  border-top: var(--triangle-size) solid #fdfdfd;
  bottom: -8px;
  right: 10px;
}

.password-requirements p {
  font-size: 0.9rem;
  color: #ff3860;
}

.password-requirements p.is-success {
  color: #23d160;
}
</style>

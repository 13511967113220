<template>
  <div class="columns mt-4 is-multiline" id="dashboard-main">
    <GoUp viewId="dashboard-main" />
    <MultiSearcher
      @search="updateStats"
      :activeSections="['startDate', 'endDate', 'recruiters']"
    />

    <VacanciesBreakdown :loadFunction="searchVacancyBreakdown" :search="search" :searchPositionsReport="searchPositionsReport" />

    <HistoricApplications
      :loadFunction="searchApplicationsBreakdown"
      :search="search"
    />

    <HistoricCandidates :loadFunction="searchCandidatesBreakdown" :search="search" />

    <HistoricCompanies
      :loadFunction="searchCompaniesBreakdown"
      :search="search"
    />

    <div class="is-flex is-justify-content-space-between column is-12 p-0" style="gap: 1.5rem;">
      <VacanciesIndicators :loadFunction="searchIndicators" :search="search" />

      <VacanciesRecruitment :loadFunction="searchRecruitment" :search="search" />
    </div>
  </div>
</template>

<script>
import GoUp from '../components/dashboard/GoUp.vue';
import MultiSearcher from '@/components/dashboard/MultiSearcher.vue';
import VacanciesBreakdown from '@/components/dashboard/VacanciesBreakdown.vue';
import VacanciesIndicators from '../components/dashboard/VacanciesIndicators.vue';
import VacanciesRecruitment from '../components/dashboard/VacanciesRecruitment.vue';
import HistoricApplications from '../components/dashboard/HistoricApplications.vue';
import HistoricCandidates from '../components/dashboard/HistoricCandidates.vue';
import HistoricCompanies from '../components/dashboard/HistoricCompanies.vue';

import {
  searchFunnelApplications,
  searchPositionsReport,
  searchVacancyBreakdown,
  searchApplicationsBreakdown,
  searchIndicators,
  searchRecruitment,
  searchRejections,
  searchApplicationsGrossProfile,
  searchVacanciesGrossProfile,
  searchVacanciesContactList,
  searchCompaniesBreakdown,
  searchCandidatesBreakdown
} from "@/api/stats";

export default {
  name: "UsersDashboard",
  components: {
    GoUp,
    MultiSearcher,
    VacanciesBreakdown,
    HistoricApplications,
    HistoricCandidates,
    VacanciesIndicators,
    VacanciesRecruitment,
    HistoricCompanies,
  },
  data() {
    return {
      searchFunnelApplications,
      searchPositionsReport,
      searchVacancyBreakdown,
      searchApplicationsBreakdown,
      searchIndicators,
      searchRecruitment,
      searchRejections,
      searchApplicationsGrossProfile,
      searchVacanciesGrossProfile,
      searchVacanciesContactList,
      searchCompaniesBreakdown,
      searchCandidatesBreakdown,
      search: {
        startDate: null,
        endDate: null,
        recruiters: [],
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.session.user;
    },
  },
  methods: {
    async updateStats(search) {
      delete search.company;

      if (this.user.role == "recruiter")
        // If recruiter, only show his stats
        search.recruiters = [this.user.id];

      this.search = search;
    },
  },
};
</script>

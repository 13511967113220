<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ renameField(commentsTranslation.title) }}
            <f-icon icon="fa-regular fa-comments" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Comments -->
        <div class="column pb-0">
          <b-field :label="commentsTranslation.placeholder">
            <b-input
              v-model="form.comments"
              type="textarea"
              maxlength="512"
              :placeholder="newPlaceholder(fieldNames.comments)"
            />
          </b-field>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import {
    getDefaultVacancyFields,
  } from "@/lib/vacancyDictionaries";

  import { mapGetters } from "vuex";

  export default {
    components: {
    },
    props: {
      commentsData: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
      editData: {
        type: Object,
        required: false,
        default : () => {}
      }
    },
    mounted() {
      this.mountedPromises();
      // Set default values
      if(this.commentsData) {
        this.form = this.commentsData;
      }
    },
    data() {
      return {
        form: {
          comments: null,
        },
      };
    },
    watch: {
      editData: {
        handler: async function () {
          // Solo agregamos los valores definidos en lel formulario de este componente
          // con los valores que vienen de la BD
          const dataFromDB = Object.keys(this.form).reduce((acc, key) => {
            acc[key] = this.editData[key] !== undefined ? this.editData[key] : this.form[key];
            return acc;
          }, {});      
          return this.form = dataFromDB;
        },
      },
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      fieldNames() {
        return getDefaultVacancyFields(process.env.VUE_APP_LOCATION);
      },
      commentsTranslation () {
        return this.$t("screens.vacancies.edit.project.fields.comments");
      }
    },
    methods: {
      async mountedPromises() {
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return this.form
        }
        return false
      },
    },
  };
</script>

<style >

</style>

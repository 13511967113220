<template>
  <ValidationObserver ref="observer">
    <div class="column">
      <!-- Section Title -->
      <div class="columns mb-4">
        <div class="column">
          <p class="title is-4 has-text-primary">
            {{ skillsDataTranslation.title }}
            <f-icon icon="fa-regular fa-books" class="ml-2"/>
          </p>
        </div>
      </div>

      <!-- Form -->
      <div class="columns is-multiline px-3">
        <!-- Skills -->
        <div class="column is-12">
          <!-- title -->
          <h1 class="title is-5">{{ renameField(skillsDataTranslation.fields.skills.label) }}</h1>
          <!-- Info -->
          <h1 class="subtitle is-6">
          {{ skillsDataTranslation.fields.skills.info }}
          </h1>

          <!-- List of skills -->
          <div
            v-for="(skill, index) in form.skills"
            :key="`${index + 1}.`"
            class="columns is-multiline align-flex-end is-12"
          >
            <div class="column is-5">
              <ValidationProvider
               :ref="'skillProvider' + index"
                rules="required"
                name="Conocimiento"
                v-slot="{ errors, valid }"
              >
                <b-field
                  style="height: 3rem"
                  horizontal
                  :label="`${index + 1}.`"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input
                    :placeholder="newPlaceholder(skillsDataTranslation.fields.skills.label)"
                    icon="award"
                    v-model="skill.name"
                    type="text"
                    @blur="validateSkills(index)"
                  />
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-1 pb-20 pl-12">
              <b-button
                outlined
                type="is-danger"
                icon-left="trash"
                @click="deleteSkill(index)"
              />
            </div>
          </div>

          <!-- Add habilities -->
          <div class="column is-3 p-0">
            <b-button
              expanded
              type="is-primary"
              @click="addSkill"
            >
              <f-icon icon="fa-regular fa-screwdriver-wrench" class="mr-2"/>
              {{ skillsDataTranslation.fields.skills.addSkillButton.label }}
            </b-button>
          </div>
        </div>

        <!-- Knowledges -->
        <div class="column is-12">
          <!-- Title -->
          <h1 class="title is-5">{{ renameField(skillsDataTranslation.fields.knowledges.label) }}</h1>
          <!-- Info -->
          <h1 class="subtitle is-6">
            {{ skillsDataTranslation.fields.knowledges.info }}
          </h1>

          <!-- List of knowledges -->
          <div
            v-for="(knowledge, index) in form.knowledges"
            :key="`${index + 1}.`"
            class="columns is-multiline align-flex-end mb-0"
          >
            <!-- Knowledge -->
            <div class="column is-6">
              <ValidationProvider
                :ref="'knowledgeProvider' + index"
                rules="required"
                name="Conocimiento"
                v-slot="{ errors, valid }"
              >
                <b-field
                  style="height: 4rem"
                  horizontal
                  :label="`${index + 1}.`"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-autocomplete
                    :placeholder="newPlaceholder(skillsDataTranslation.fields.knowledges.label)"
                    icon="cube"
                    v-model="knowledge.name"
                    maxlength="64"
                    :data="filteredKnowledges"
                    :open-on-focus="true"
                    :clearable="true"
                    field="name"
                    @focus="focusedKnowledge(index)"
                    @select="(option) => (selectedKnowledge = option)"
                    @blur="validateKnowledge(index)"
                  >
                  </b-autocomplete>
                </b-field>
              </ValidationProvider>
            </div>

            <!-- Level -->
            <div class="column is-5">
              <ValidationProvider
                :ref="'levelProvider' + index"
                rules="required"
                name="Nivel conocimiento"
                v-slot="{ errors, valid }"
              >
                <b-field
                  style="height: 4rem"
                  horizontal
                  :label="skillsDataTranslation.fields.knowledges.level.label"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-select
                    v-model="knowledge.level"
                    icon="signal-alt-3"
                    expanded
                    @blur="validateLevel(index)"
                  >
                    <option
                      v-for="knowledgeLevel in knowledgeLevels"
                      :key="`${knowledgeLevel}`"
                      :value="knowledgeLevel"
                    >
                      {{ knowledgeLevel }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-1 pb-36 pl-12">
              <b-button
                outlined
                type="is-danger"
                icon-left="trash"
                @click="deleteKnowledge(index)"
              />
            </div>
          </div>

           <!-- Add knowledges -->
           <div class="column is-3 p-0">
            <b-button
              expanded
              type="is-primary"
              @click="addKnowledge"
            >
              <f-icon icon="fa-regular fa-book" class="mr-2"/>
              {{ skillsDataTranslation.fields.knowledges.addKnowledgeButton.label }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
  import {
    knowledgeLevels
  } from "../../lib/vacancyDictionaryout";
  import {
    getKnowledges,
  } from "../../api/vacancy";
  
  import { mapGetters } from "vuex";

  export default {
    components: {
    },
    props: {
      habilities: {
        type: Object,
        required: false,
      },
      renameField: {
        type: Function,
        required: true,
      },
      newPlaceholder: {
        type: Function,
        required: true,
      },
      editData: {
        type: Object,
        required: false,
        default : () => {}
      }
    },
    mounted() {
      this.mountedPromises();

      // Set default values
      if(this.habilities) {
        this.form = this.habilities;
      }
    },
    data() {
      return {
        knowledges: [],
        knowledgeIndex: 0,
        form: { 
          skills: [],
          knowledges: [],
        },
      };
    },
    watch: {
      "$i18n.locale"(){
        this.revalidateFields();
      },
      editData: {
        handler: async function () {
          // Solo agregamos los valores definidos en lel formulario de este componente
          // con los valores que vienen de la BD
          const dataFromDB = Object.keys(this.form).reduce((acc, key) => {
            acc[key] = this.editData[key] !== undefined ? this.editData[key] : this.form[key];
            return acc;
          }, {});              
          return this.form = dataFromDB
        },
      },
    },
    computed: {
      ...mapGetters(["companyId", "projectId"]),
      skillsDataTranslation() {
        return this.$t("screens.vacancies.edit.habilities");
      },
      knowledgeLevels() {
        return knowledgeLevels[this.$i18n.locale];
      },
      filteredKnowledges() {
        if (this.knowledgeIndex >= 0 && this.form.knowledges[this.knowledgeIndex]?.name) {
          return this.knowledges.filter((knowledge) => {
            if (this.knowledgeIndex >= 0) {
              return (
                knowledge.name.toLowerCase().indexOf(this.form.knowledges[this.knowledgeIndex].name.toLowerCase()) >= 0
              );
            }
          });
        } else return this.knowledges;
      },
    },
    methods: {
      async mountedPromises() {
        this.knowledges = await getKnowledges(this.companyId);
      },
      addSkill() {
        this.form.skills.push({
          name: "",
        });
      },
      deleteSkill(index) {
        this.form.skills.splice(index, 1);
      },
      addKnowledge() {
        if (this.form.knowledges.length < 10) {
          this.form.knowledges.push({
            name: "",
            level: "",
          });
        } else
          this.$toast.warning({
            message: this.skillsDataTranslation.fields.knowledges.infoLimit,
          });
      },
      deleteKnowledge(index) {
        this.form.knowledges.splice(index, 1);
      },
      focusedKnowledge(index) {
        this.knowledgeIndex = index;
      },
      async validateForm(){
        const valid = await this.$refs.observer.validate()

        if(valid){
          return this.form
        }
        return false
      },
      validateKnowledge(index) {
        const providerRef = this.$refs['knowledgeProvider' + index];
        if (providerRef && providerRef[0]) {
          providerRef[0].validate();
        }
      },
      validateLevel(index) {
        const providerRef = this.$refs['levelProvider' + index];
        if (providerRef && providerRef[0]) {
          providerRef[0].validate();
        }
      },
      validateSkills(index) {
        const providerRef = this.$refs['skillProvider' + index];
        if (providerRef && providerRef[0]) {
          providerRef[0].validate();
        }
      },
      revalidateFields() {
        Object.keys(this.$refs).forEach((key) => {
          const refs = this.$refs[key];
          // Verifica que 'refs' es un array antes de intentar validarlo
          if (Array.isArray(refs)) {
            refs.forEach((ref) => {
              if (ref.flags && ref.flags.touched) {
                ref.validate();
              }
            });
          }
        });
      }
    },
  };
</script>

<style scoped>
  @import "https://unicons.iconscout.com/release/v2.1.11/css/unicons.css";
</style>
